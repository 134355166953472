import styles from './page-footer.module.scss';
import logo1kpo from '../../../assets/logos/logo1_kpo.png';
import logo2rp from "../../../assets/logos/logo2_rp.png";
import logo3eu from "../../../assets/logos/logo3_eu.png";
export function PageFooter() {
    return <footer className={styles.footer}>
        <img src={logo1kpo} alt="" />
        <img src={logo2rp} alt="" />
        <img src={logo3eu} alt="" />
    </footer>
}
