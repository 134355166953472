import { GameScenario, GAME_STEP_TYPE } from "./types";
import cm from "../../assets/icons/popups/cm_pop.svg";
import dl from "../../assets/icons/popups/dl_pop.svg";
import dr from "../../assets/icons/popups/dr_pop.svg"
import kaf from "../../assets/icons/popups/kaf_pop.svg"
import kap from "../../assets/icons/popups/kap_pop.svg"
import mlyn from "../../assets/icons/popups/mlyn_pop.svg"
import osp from "../../assets/icons/popups/osp_pop.svg"
import ryn from "../../assets/icons/popups/ryn_pop.svg"
import syn from "../../assets/icons/popups/syn_pop.svg"
import szkola from "../../assets/icons/popups/szkola_pop.svg"
import cerkizbor from "../../assets/icons/popups/cerkizbor_pop.svg"
import cyryl from "../../assets/icons/popups/cyryla_pop.svg"
import ma from "../../assets/icons/popups/michala_pop.svg"
import sad from "../../assets/icons/popups/sad_pop.svg"


export const SCENARIO: GameScenario = {
    startStep: '0.1',
    steps: [
        {
            id: '0.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-map',
            nextStep: null,
        },
        {
            id: '1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.71284, 
                lng: 23.32066
                
            },
            name: ['Ogród włoski inaczej Sad'],
            image: sad,
            nextStep: '1.1'
        },
        {
            id: '1.1', //
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-1', 
            nextStep: '0.1'
        },
        {
            id: '2',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70958, 
                lng: 23.32302
                
            },
            name: ['Młyn'],
            image: mlyn,
            nextStep: '2.1'
        },
        {
            id: '2.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-2', //zrobione
            nextStep: '0.1'
        },
        {
            id: '3',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.7117, 
                lng: 23.3178
                
            },
            name: ['Kaflarnia'],
            image: kaf,
            nextStep: '3.1'
        },
        {
            id: '3.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-3', //zrobione
            nextStep: '0.1'
        },
        {
            id: '4',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70814,  
                lng: 23.32551
                
            },
            name: ['Dwór Radziwiłłów'],
            image: dr,
            nextStep: '4.1'
        },
        {
            id: '4.1',//
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-4', 
            nextStep: '0.1'
        },
        {
            id: '5',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.7071, 
                lng: 23.3274
                
            },
            image: cerkizbor,
            name: ['Cerkiew i Zbór'],
            nextStep: '5.1'
        },
        {
            id: '5.1', //
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-5', 
            nextStep: '0.1'
        },
        {
            id: '6',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.7078, 
                lng: 23.32667
                
            },
            name: ['Szkoła'], 
            image: szkola,
            nextStep: '6.1'
        },
        {
            id: '6.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-6', //zrobione
            nextStep: '0.1'
        },
        {
            id: '7',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70809, 
                lng: 23.32604
                
            },
            name: ['Kaplica rzymsko-katolicka'],
            image: kap,
            nextStep: '7.1'
        },
        {
            id: '7.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-7', 
            nextStep: '0.1'
        },{
            id: '8',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70427, 
                lng: 23.33371
                
            },
            name: ['Synagoga'],
            image: syn,
            nextStep: '8.1'
        },
        {
            id: '8.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-8', //zrobione
            nextStep: '0.1'
        },{
            id: '9',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70273, 
                lng: 23.33286
                
            },
            name: ['Cerkiew Michała Archanioła'],
            image: ma,
            nextStep: '9.1'
        },
        {
            id: '9.1', //
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-9', 
            nextStep: '0.1'
        },{
            id: '10',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70364, 
                lng: 23.33194
                
            },
            name: ['Rynek'],
            image: ryn,
            nextStep: '10.1'
        },
        {
            id: '10.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-10', //zrobione
            nextStep: '0.1'
        },{
            id: '11',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.71089, 
                lng: 23.33446
                
            },
            name: ['Cmentarz żydowski'],
            image: cm,
            nextStep: '11.1'
        },
        {
            id: '11.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-11', //zrobione
            nextStep: '0.1'
        },{
            id: '12',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.71201, 
                lng: 23.33017
                
            },
            image: cyryl,
            name: ['Cerkiew Cyryla i Metodego'],
            nextStep: '12.1'
        },
        {
            id: '12.1', //
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-12', 
            nextStep: '0.1'
        },{
            id: '13',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70614, 
                lng: 23.3306
                
            },
            name: ['Ośrodek zdrowia'],
            image: dl,
            nextStep: '13.1'
        },
        {
            id: '13.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-13', //zrobione
            nextStep: '0.1'
        },
        {
            id: '14',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.70449, 
                lng: 23.33485,
                
            },
            name: ['Straż pożarna'],
            image: osp,
            nextStep: '14.1'
        },
        {
            id: '14.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-14', //zrobione
            nextStep: '0.1'
        },




        {
            id: 'game-complete',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'game-complete',
            nextStep: '15.1'
        },
        {
            id: 'summary-page',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'summary-page',
            nextStep: null
        },
    ]
}
